import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from '@/router';
import store from '@/store';
import * as api from '../src/network/api'
import axios from 'axios'
import {
	convert,
	encrypt,
	decrypt,
	verification
} from './utils/auth.js'
import {
	getSign
} from './utils/md5.js'
import {
	getwxsdk
} from './utils/getwxsdk.js'
import {
	BASE_URL,
	PIC_URL
} from "@/constant";
import Pagination from '@/components/pagination.vue';
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '../src/theme/index.css'
Vue.use(ElementUI);
Vue.component('Pagination', Pagination)
import wxsdk from '/src/wxsdk.js'
import 'amfe-flexible'

import Antd from 'ant-design-vue'
import './theme/ant.css'
Vue.use(Antd)
import {MessageBox} from 'element-ui'
// import VConsole from 'vconsole'
// const vConsole = new VConsole()
// Vue.use(vConsole)

Vue.prototype.$api = api
Vue.prototype.$axios = axios
Vue.prototype.$convert = convert
Vue.prototype.$encrypt = encrypt
Vue.prototype.$decrypt = decrypt
Vue.prototype.$verification = verification
Vue.prototype.$getSign = getSign
// Vue.prototype.$baseURL = process.env.VUE_APP_BASE_API
Vue.prototype.$baseURL = PIC_URL
Vue.config.productionTip = false
Vue.prototype.$wxsdk = wxsdk
Vue.prototype.$getwxsdk = getwxsdk
Vue.prototype.$confirm = MessageBox.confirm



new Vue({
	router,
	store,
	created() {
		// 在页面加载时读取localStorage里的状态信息并恢复Vuex的状态
		if (localStorage.getItem("store")) {
			let storet = localStorage.getItem("store");
			this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(storet == null ? '' : storet)));
		}
	},
	render: h => h(App),
}).$mount('#app')