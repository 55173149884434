<template>
	<div class="header-box">
		<div class="header">
			<div @click="getHome" class="logo-box">
				<img class="logo" :src="homeDate.logo_url" alt="">
				<div class="logo-title-box">
					<p>北京市体育竞赛管理和国际交流中心</p>
					<p class="el">Beijing Sports Competitions Administration and International Exchange Center</p>
				</div>
			</div>
			<!-- <img class="nameTitle" src="../assets/image/title.png" alt=""> -->
			<!-- <div class="searchfor">
				<input type="text" class="searinput">
			</div> -->
			<div class="searchfor">
				<el-input class="input-with-select" v-model="keyword">
					<el-button slot="append" icon="el-icon-search" @click="getsearch"></el-button>
				</el-input>
			</div>
			<!-- <img class="searchfor" src="../assets/image/searchfor.png" alt=""> -->
			<div class="but-box">
				<div class="but">
					<div><a href="/">首 页｜</a></div>
					<div style="display: flex;" v-if="this.$store.state.token != '' && this.$store.state.userType == 'user_type_01'">
						<span>欢迎您,</span>
						<span class="roundtitle" v-if="this.$store.state.realnameornot.requeststatus == 1 || this.$store.state.realnameornot.caStatus == 1"><a @click="determine" style="color: #0047A5;">{{this.$store.state.realnameornot.realname}}</a>｜</span>
						<span class="roundtitle" v-else><a @click="determine" style="color: #0047A5;">{{this.$store.state.user.phone}}</a>｜</span>
					</div>
					<div style="display: flex;" v-if="this.$store.state.token != '' && this.$store.state.userType == 'user_type_02' ">
						<span>欢迎您,</span>
						<span class="roundtitle" v-if="groupdate.requeststatus == 1"><a @click="determine" style="color: #0047A5;">{{groupdate.name}}</a>｜</span>
						<span class="roundtitle" v-else><a @click="determine" style="color: #0047A5;">{{this.$store.state.user.phone}}</a>｜</span>
					</div>
					<div v-if="this.$store.state.token == ''" @click="center">用户中心｜</div>
					<div v-if="this.$store.state.token == ''"><a href="/register">账号注册</a></div>
					<div v-if="this.$store.state.token != ''" @click="exit">退 出</div>
				</div>
			</div>
		</div>
		<div class="nav" id="navbars">
			<ul id="list">
				<li v-for="(item, index) in menuItems" :key="index" :class="{ active: activeIndex === index }">
					<a @click="toggleSubMenu(item.url, index, item.parentId, item.id, item)">{{ item.name }}</a>
					<img class="triangle" src="../assets/image/triangle.png" alt="">
					<ul v-if="item.children" :id="'u' + (index + 1)" class="erji">
						<li v-for="(subItem, subIndex) in item.children" :key="subIndex">
							<a @click="jump(subItem.url, index, subItem.parentId, subItem.id,subItem)">{{ subItem.name }}</a>
							<img class="triangle2" src="../assets/image/triangle.png" alt="" v-if="subItem.name == '培训课堂'">
							<ul class="dp">
								<li v-for="(sanitem, numindex) in subItem.children" :key="numindex">
									<a @click="jump2(sanitem.id,sanitem.parentId,Number(numindex + 1),index)">{{sanitem.name}}</a>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				menuList: [{
						name: '成绩查询',
						url: '/game'
					},
					{
						name: '最高纪录',
						url: '/record'
					},
					{
						name: '赛事报名',
						url: '/eventregistration'
					},
					{
						name: '登记/查询',
						url: '/eventquery'
					},
					{
						name: '注册/查询',
						url: '/registrationquery'
					},
					{
						name: '专题专栏',
						url: '/specialsubject'
					},
					{
						name: '北京竞技场',
						url: '/specialsubject'
					},
					{
						name: '竞赛知识',
						url: '/competition'
					},
					{
						name: '裁判员培训',
						url: '/competition'
					},
				],
				menuItems: [],
				activeIndex: '',
				homeDate: {},
				keyword: '',
				groupdate: {}
			}
		},
		created() {
			this.$store.commit("EXIT_STATIONID", '1005012')
			this.findStationById()
			this.getNav()
			if (this.$store.state.userType == 'user_type_02' && this.$store.state.token != '') {
				this.getCommunityAuthenInfo()
			}
			if(this.$store.state.userType == 'user_type_01' && this.$store.state.token != ''){
				this.$store.commit("EXIT_REALN")
			}
			
			if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
			  document.documentElement.className += ' ie-browser';
			}
		},
		mounted() {},
		methods: {
			getCommunityAuthenInfo() {
				this.$api.getCommunityAuthenInfo({
					userid: this.$store.state.userid
				}).then(res => {
					this.groupdate = res.data.data.result
				})
			},
			findStationById() {
				this.$api.findStationById(this.$store.state.stationId).then(res => {
					this.homeDate = res.data.data
				})
			},
			getNav() {
				this.$api.getNav().then(res => {
					res.data.data.forEach(item => {
						res.url = '#'
						if (item.name == '北京竞技场') {
							item.url = '/specialsubject'
							item.difference = 1
						}
						if (item.name == '综合资讯') {
							let obj = {
								children: [{
										id: 803,
										name: "裁判员培训",
										parentId: 739
									},
									{
										id: 829,
										name: "赛事培训",
										parentId: 739
									}
								],
								name: '培训课堂'
							}
							item.children.splice(4, 0, obj)
						}

						if (item.children.length > 0) {
							item.children.forEach(crr => {
								for (let i in this.menuList) {
									if (this.menuList[i].name == crr.name) {
										crr.url = this.menuList[i].url
										crr.difference = 1
									}
								}
							})
						}
					})
					this.menuItems = res.data.data
					// let obj2 = {
					// 	name: '专题专栏',
					// 	url: '/specialsubject',
					// 	id: ''
					// }
					// this.menuItems.push(obj2)
				})
			},
			toggleSubMenu(path, index, pid, id, row) {
				if (this.activeIndex === index) {
					this.activeIndex = null;
				} else {
					this.activeIndex = index;
				}
				if (path == undefined) return
				this.$router.push({
					path: path,
					query: {
						refresh: new Date().getTime()
					},
				});
			},
			jump(path, index, pid, id, row) {
				if(row.name == '培训课堂') return
				if(row.name == '精彩瞬间'){
					return window.open(row.linkAddress, '_self');
				}
				this.activeIndex = index;
				if (row.name == '赛事登记') {
					if (this.$store.state.token == '') {
						this.$store.commit("EXIT_TOKEN")
						this.$router.push({
							path: '/login',
							query: {
								person: false,
								grp: true,
								refresh: new Date().getTime()
							}
						})
						return
					}
					if (this.$store.state.userType == 'user_type_01') {
						this.$confirm('当前账号为个人账号，是否切换成团体账号', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$store.commit("EXIT_TOKEN")
							this.$router.push({
								path: '/login',
								query: {
									person: false,
									grp: true,
									refresh: new Date().getTime()
								}
							})
						}).catch(() => {});

						return

					}
					this.$api.getTimeStamp().then(ess => {
						let data = {
							accessToken: this.$store.state.token,
							redirectURL: '/sportMatch/SportMatchList',
							timestampe: ess.data.data.timestamp
						}
						this.$api.getCode(data).then(err => {
							let url = process.env.VUE_APP_BASE_API_ADMIN + '/tokenLogin?code=' + err.data
								.data.result.code
							window.open(url, '_self');
						})
					})
				} else {
					if (row.hasLink == 1) {
						window.open(row.linkAddress, '_blank');
					} else if (row.difference != 1) {
						this.$router.push({
							path: '/notice',
							query: {
								pid: pid,
								id: id,
								template: row.template,
								refresh: new Date().getTime()
							}
						})
					} else {
						this.activeIndex = index;
						// 执行跳转逻辑
						this.$router.push({
							path: path,
							query: {
								pid: pid,
								id: id,
								template: row.template,
								refresh: new Date().getTime()
							},
						});
					}
				}
			},
			jump2(id, pid, e,index) {
				this.activeIndex = index;
				if (e == 2) {
					this.$router.push({
						path: '/notice',
						query: {
							pid: pid,
							id: id,
							refresh: new Date().getTime()
						}
					})
				} else {
					this.$router.push({
						path: '/competition',
						query: {
							pid: pid,
							id: id,
							refresh: new Date().getTime()
						}
					})
				}
			},
			exit() {
				this.$api.checkLogout({
					accessToken: this.$store.state.token
				}).then(res => {
					this.$message.success(res.data.data.message);
					setTimeout(() => {
						this.$store.commit("EXIT_TOKEN")
						this.$router.push({
							path: '/login'
						})
					}, 500)
				})
			},
			center() {
				if (this.$store.state.token == '') {
					this.$router.push({
						path: '/login'
					})
				} else {
					this.$router.push({
						path: '/registerIndex'
					})
				}
			},
			determine() {
				if (this.$store.state.userType == 'user_type_01') {
					this.$router.push({
						path: '/registerIndex',
						query: {
							refresh: new Date().getTime()
						},
					})
				} else {
					this.$api.getTimeStamp().then(ess => {
						let data = {
							accessToken: this.$store.state.token,
							redirectURL: '/authIndex',
							timestampe: ess.data.data.timestamp
						}
						this.$api.getCode(data).then(err => {
							let url = process.env.VUE_APP_BASE_API_ADMIN + '/tokenLogin?code=' + err.data
								.data.result.code
							window.open(url, '_self');
						})
					})
				}
			},
			getsearch() {
				this.$router.push({
					path: '/search',
					query: {
						keyword: this.keyword,
						refresh: new Date().getTime()
					},
				})
			},
			getHome() {
				this.$router.push({
					path: '/',
					query: {
						refresh: new Date().getTime()
					},
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.active {
		width: 180PX;
		color: #fff;
		background-color: #0A6DF3;
		margin-right: 10PX;
	}

	body {
		margin: 0;
		padding: 0;
	}

	.header {
		display: flex;
		width: 1200PX;
		margin: 54PX auto 0 auto;
	}

	.logo-box {
		display: flex;
	}

	.logo {
		width: 76PX;
		height: 72PX;
		cursor: pointer;
	}

	.logo-title-box {
		font-family: MicrosoftYaHei;
		margin-left: 10PX;
		cursor: pointer;

		p {
			font-size: 28PX;
			margin-top: 4PX;
			letter-spacing: 1.3PX;
		}

		.el {
			font-size: 12PX;
			margin-top: 6PX;
			letter-spacing: 0PX;
		}
	}

	.img {
		image-rendering: -moz-crisp-edges;
		image-rendering: -o-crisp-edges;
		image-rendering: -webkit-optimize-contrast;
		image-rendering: crisp-edges;
		-ms-interpolation-mode: nearest-neighbor;
	}

	.nameTitle {
		width: 564PX;
		height: 73PX;
	}

	.searchfor {
		width: 210PX;
		height: 34PX;
		margin-left: 180PX;
		margin-top: 18PX;
		flex-shrink: 0;
	}

	.searchfor ::v-deep .el-input__inner {
		height: 34PX;
		border: 2PX solid #949494;
	}

	.searchfor ::v-deep .el-input-group__append {
		border: 1PX solid #949494;
		color: #fff;
		background-color: #949494;
	}

	.searinput {
		width: 210PX;
		height: 34PX;
		border: 2PX solid #949494;
		border-radius: 7PX;
		padding-left: 10PX;
		font-size: 15PX;
	}

	.but-box {
		display: flex;
		height: 18PX;
		font-size: 16PX;
		font-family: MicrosoftYaHei;
		font-weight: 400;
		color: #333333;
		margin-left: 34PX;
		margin-top: 26PX;
		flex-shrink: 0;
	}

	.but-box .but {
		display: flex;
		cursor: pointer;
	}

	.nav {
		height: 60PX;
		background: #164B92;
		margin-top: 31PX;
		display: flex;
		justify-content: center;
		position: relative;
		z-index: 1;
	}

	#list {
		display: block;
		margin: 0PX;
		padding: 0PX;
		width: 1200PX;
		height: 60PX;
		z-index: 9999;
		display: flex;
		justify-content: center;
	}

	#list>li {
		float: left;
		height: 60PX;
		cursor: pointer;
	}

	#list>li>ul {
		display: none;
		position: absolute;
		float: none;
		width: 180PX;
		font-size: 15PX;
		background: #0A6DF3;
		padding-left: 0PX;
	}

	#list>li>a {
		font-size: 25PX;
		font-family: MicrosoftYaHei;
		font-weight: 400;
		color: #fff;
		position: relative;
		text-decoration: none;
		width: 180PX;
		margin-right: 10PX;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 60PX;
	}

	#list>li>ul>li {
		width: 180PX;
		font-size: 15PX;
		text-align: center;
		cursor: pointer;
		position: relative;
		z-index: 9999;
	}

	#list>li:hover .erji {
		display: block;
	}

	#list>li:hover a {
		background: #0A6DF3;
	}

	#list>li>ul>li>a {
		font-size: 18PX;
		font-family: MicrosoftYaHei;
		font-weight: 400;
		color: #fff;
		position: relative;
		text-decoration: none;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 180PX;
		border-top: 1PX solid #164B92;
		padding: 10PX 0;
	}

	#list>li>ul>li>a:hover {
		background: #164B92;
	}

	#list ul li:hover ul {
		display: block;
	}

	ul {
		list-style-type: none;
	}

	#list .dp {
		display: none;
		position: absolute;
		margin-left: 182PX;
		margin-top: -90PX;
	}

	#list .dp a {
		font-size: 18PX;
		font-family: MicrosoftYaHei;
		font-weight: 400;
		color: #fff;
		position: relative;
		text-decoration: none;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 180PX;
		border-top: 1PX solid #164B92;
		padding: 10PX 0;
	}

	#list .dp a:hover {
		background: #164B92;
	}

	#list .dp:hover .dp {
		display: block;
	}

	.triangle {
		width: 16PX;
		height: 16PX;
		position: absolute;
		margin-top: -12PX;
		margin-left: 82PX;
	}

	.triangle2 {
		width: 16PX;
		height: 16PX;
		position: absolute;
		margin-top: -30PX;
		// margin-left: 76PX;
		transform: rotate(90deg);
		right: 5PX;
	}

	.roundtitle {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		cursor: pointer;
		max-width: 70PX;
		text-align: right;
	}

	::v-deep .el-icon-search {
		font-size: 22PX;
		display: flex;
		justify-content: center;
	}

	.input-with-select {
		font-size: 15PX;
	}

	.searchfor ::v-deep .el-button {
		width: 40PX;
	}

	::v-deep .el-input__inner {
		border-radius: 7PX;
	}

	.searchfor ::v-deep .el-input-group__append {
		border-top-right-radius: 7PX;
		border-bottom-right-radius: 7PX;
	}
	
	.ie-browser .input-with-select{
		::v-deep .el-icon-search{
			margin-left: -11PX;
			margin-top: 2PX;
		}
		::v-deep .el-input-group__append{
			border: 2.3PX solid #949494;
		}
		::v-deep .el-icon-search {
			font-size: 22PX;
			display: block;
		}
	}
</style>