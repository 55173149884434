import axios from 'axios';
import CryptoJS from 'crypto-js'
const key = 'capinfo@123!test'
// 偏移量 16位（不可随意修改，否则前后端加密解密可能失败）
const iv = '0000000000000000'

async function convert(url) {
  try {
    const response = await axios({
      method: "POST",
      data: {
        url: url
      },
      url: process.env.VUE_APP_BASE_API + "/api/download",
      responseType: "blob",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
    });

    const binaryData = [];
    binaryData.push(response.data);
    const fileBlob = new Blob(binaryData, {
      type: response.data.type
    });
    const fileUrl = window.URL.createObjectURL(fileBlob);
	
    return fileUrl;
  } catch (error) {
    console.error("File download failed:", error);
    // 处理下载失败的逻辑
  }
}

// ase加密
function encrypt(data) {
  return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
}

// ase解密
function decrypt(data) {
  data = data .replace(/[\r\n]/g, ''); 
  const decrypted = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return decrypted.toString(CryptoJS.enc.Utf8).toString()
}

//  校验空值、null、undefined、[]
function verification(val) {
  let valString = val + ""
  let verificationType = ["", "null", "undefined"]
  return verificationType.includes(valString)
}

export { convert , encrypt, decrypt, verification }
