import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from 'vue-meta'
import wxsdk from '/src/wxsdk.js'
import store from "@/store/index"
import {
	MessageBox,
	Message
} from 'element-ui'

Vue.use(VueRouter);
Vue.use(VueMeta)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		window.location.reload()
	})
}

const routes = [{
		path: "/",
		name: "home",
		meta: {
			title: "首页"
		},
		component: () => import("@/pages/home.vue")
	},
	{
		path: "/notice",
		name: "notice",
		meta: {
			title: "文章列表"
		},
		component: () => import("@/pages/notice.vue")
	},
	{
		path: "/search",
		name: "search",
		meta: {
			title: "搜索列表"
		},
		component: () => import("@/pages/search.vue")
	},
	// {
	//     path: "/centraldynamics",
	//     name: "centraldynamics",
	//     meta:{
	//         title:"中心动态"
	//     },
	//     component: () => import("@/pages/centraldynamics.vue")
	// },
	// {
	//     path: "/policiesregulations",
	//     name: "policiesregulations",
	//     meta:{
	//         title:"政策法规"
	//     },
	//     component: () => import("@/pages/policiesregulations.vue")
	// },
	{
		path: "/briefintroduction",
		name: "briefintroduction",
		meta: {
			title: "单位简介"
		},
		component: () => import("@/pages/briefintroduction.vue")
	},
	{
		path: "/articleDetails",
		name: "articledetails",
		meta: {
			title: "文章详情"
		},
		component: () => import("@/pages/article-details.vue")
	},
	{
		path: "/login",
		name: "login",
		meta: {
			title: "登录"
		},
		component: () => import("@/pages/login.vue")
	},
	{
		path: "/specialsubject",
		name: "specialsubject",
		meta: {
			title: "专题管理"
		},
		component: () => import("@/pages/specialsubject.vue")
	},
	{
		path: "/refereesdemeanor",
		name: "refereesdemeanor",
		meta: {
			title: "裁判风采"
		},
		component: () => import("@/pages/referees-demeanor.vue")
	},
	{
		path: "/registrationquery",
		name: "registrationquery",
		meta: {
			title: "注册查询"
		},
		component: () => import("@/pages/registration-query.vue")
	},
	{
		path: "/registrationinformation",
		name: "registrationinformation",
		meta: {
			title: "裁判资讯"
		},
		component: () => import("@/pages/registration-information.vue")
	},
	{
		path: "/refereeDetails",
		name: "refereedetails",
		meta: {
			title: "裁判详情"
		},
		component: () => import("@/pages/referee-details.vue")
	},
	{
		path: "/refereeDetailss",
		name: "refereedetailss",
		meta: {
			title: "裁判详情"
		},
		component: () => import("@/pages/referee-detailss.vue")
	},
	{
		path: "/game",
		name: "game",
		meta: {
			title: "成绩查询"
		},
		component: () => import("@/pages/game.vue")
	},
	{
		path: "/eventInformation",
		name: "eventinformation",
		meta: {
			title: "赛事信息"
		},
		component: () => import("@/pages/event-Information.vue")
	},
	{
		path: "/record",
		name: "record",
		meta: {
			title: "最高纪录"
		},
		component: () => import("@/pages/record.vue")
	},
	{
		path: "/eventregistration",
		name: "eventregistration",
		meta: {
			title: "赛事报名"
		},
		component: () => import("@/pages/event-registration.vue")
	},
	{
		path: "/application",
		name: "application",
		meta: {
			title: "赛事报名详情"
		},
		component: () => import("@/pages/application.vue")
	},
	{
		path: "/eventquery",
		name: "eventquery",
		meta: {
			title: "登记/查询"
		},
		component: () => import("@/pages/event-query.vue")
	},
	{
		path: "/details",
		name: "details",
		meta: {
			title: "详情"
		},
		component: () => import("@/pages/details.vue")
	},
	{
		path: "/competition",
		name: "competition",
		meta: {
			title: "竞赛知识"
		},
		component: () => import("@/pages/competition.vue")
	},
	{
		path: "/eventtheme/index",
		name: "eventTheme",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/event-theme/index.vue")
	},
	{
		path: "/eventtheme/index2",
		name: "eventTheme2",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/event-theme/index2.vue")
	},
	{
		path: "/eventtheme/index3",
		name: "eventTheme3",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/event-theme/index3.vue")
	},
	{
		path: "/eventtheme/index4",
		name: "eventTheme4",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/event-theme/index4.vue")
	},
	{
		path: "/eventtheme/index5",
		name: "eventTheme5",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/event-theme/index5.vue")
	},
	{
		path: "/eventtheme/index6",
		name: "eventTheme6",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/event-theme/index6.vue")
	},
	{
		path: "/eventtheme/index7",
		name: "eventTheme",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/event-theme/index7.vue")
	},

	{
		path: "/changePassword",
		name: "changepassword",
		meta: {
			title: "修改密码"
		},
		component: () => import("@/pages/change-password.vue")
	},
	{
		path: "/register",
		name: "register",
		meta: {
			title: "注册"
		},
		component: () => import("@/pages/register.vue")
	},
	{
		path: "/rersonalRegistration",
		name: "rersonalregistration",
		meta: {
			title: "个人注册"
		},
		component: () => import("@/pages/rersonal-registration.vue")
	},
	{
		path: "/groupRegistration",
		name: "groupregistration",
		meta: {
			title: "团体注册"
		},
		component: () => import("@/pages/group-registration.vue")
	},
	{
		path: "/registerIndex",
		name: "registerIndex",
		meta: {
			title: "个人中心"
		},
		component: () => import("@/pages/register/index.vue")
	},
	{
		path: "/personalInformation",
		name: "personalInformation",
		meta: {
			title: "个人信息"
		},
		component: () => import("@/pages/register/personal-Information.vue")
	},
	{
		path: "/record",
		name: "record",
		meta: {
			title: "裁判员选派记录"
		},
		component: () => import("@/pages/register/record.vue")
	},
	{
		path: "/refereeRegistration",
		name: "refereeregistration",
		meta: {
			title: "裁判员证书"
		},
		component: () => import("@/pages/register/referee-registration.vue")
	},
	{
		path: "/refereeDetails",
		name: "refereedetails",
		meta: {
			title: "裁判注册"
		},
		component: () => import("@/pages/register/referee-details.vue")
	},
	{
		path: "/personalAuthentication",
		name: "personalauthentication",
		meta: {
			title: "个人认证"
		},
		component: () => import("@/pages/register/personal-authentication.vue")
	},
	{
		path: "/h5",
		name: "index",
		meta: {
			title: "首页",
			keepAlive: false,
		},
		component: () => import("@/pages/h5/index.vue"),
	},
	{
		path: "/h5/H5notice",
		name: "H5notice",
		meta: {
			title: "文章列表"
		},
		component: () => import("@/pages/h5/notice.vue")
	},
	{
		path: "/h5/H5special",
		name: "H5special",
		meta: {
			title: "专题列表"
		},
		component: () => import("@/pages/h5/special.vue")
	},
	{
		path: "/h5/H5articledetails",
		name: "H5articledetails",
		meta: {
			title: "文章详情"
		},
		component: () => import("@/pages/h5/article-details.vue")
	},
	{
		path: "/h5/H5eventquery",
		name: "H5eventquery",
		meta: {
			title: "登记/查询"
		},
		component: () => import("@/pages/h5/event-query.vue")
	},
	{
		path: "/h5/H5details",
		name: "H5details",
		meta: {
			title: "赛事详情"
		},
		component: () => import("@/pages/h5/details.vue")
	},
	{
		path: "/h5/H5record",
		name: "H5record",
		meta: {
			title: "最高纪录"
		},
		component: () => import("@/pages/h5/record.vue")
	},
	{
		path: "/h5/H5game",
		name: "H5game",
		meta: {
			title: "成绩查询"
		},
		component: () => import("@/pages/h5/game.vue")
	},
	{
		path: "/h5/H5eventregistration",
		name: "H5eventregistration",
		meta: {
			title: "赛事报名"
		},
		component: () => import("@/pages/h5/event-registration.vue")
	},
	{
		path: "/h5/H5application",
		name: "H5application",
		meta: {
			title: "赛事报名"
		},
		component: () => import("@/pages/h5/application.vue")
	},
	{
		path: "/h5/H5competition",
		name: "H5competition",
		meta: {
			title: "知识竞赛"
		},
		component: () => import("@/pages/h5/competition.vue")
	},
	{
		path: "/h5/H5registrationquery",
		name: "H5registrationquery",
		meta: {
			title: "裁判列表"
		},
		component: () => import("@/pages/h5/registration-query.vue")
	},
	{
		path: "/h5/H5refereedetails",
		name: "H5refereedetails",
		meta: {
			title: "裁判详情"
		},
		component: () => import("@/pages/h5/referee-details.vue")
	},
	{
		path: "/h5/H5refereedetailss",
		name: "H5refereedetailss",
		meta: {
			title: "裁判详情"
		},
		component: () => import("@/pages/h5/referee-detailss.vue")
	},
	{
		path: "/h5/login",
		name: "login",
		meta: {
			title: "登录"
		},
		component: () => import("@/pages/h5/login.vue")
	},
	{
		path: "/h5/changePassword",
		name: "H5changepassword",
		meta: {
			title: "修改密码"
		},
		component: () => import("@/pages/h5/change-password.vue")
	},
	{
		path: "/h5/register",
		name: "H5register",
		meta: {
			title: "注册"
		},
		component: () => import("@/pages/h5/register.vue")
	},
	{
		path: "/h5/rersonalRegistration",
		name: "H5rersonalregistration",
		meta: {
			title: "个人注册"
		},
		component: () => import("@/pages/h5/rersonal-registration.vue")
	},
	{
		path: "/h5/groupRegistration",
		name: "H5groupregistration",
		meta: {
			title: "团体注册"
		},
		component: () => import("@/pages/h5/group-registration.vue")
	},
	{
		path: "/h5/registerIndex",
		name: "H5registerIndex",
		meta: {
			title: "个人中心"
		},
		component: () => import("@/pages/h5/register/index.vue")
	},
	{
		path: "/h5/search",
		name: "H5search",
		meta: {
			title: "搜索列表"
		},
		component: () => import("@/pages/h5/search.vue")
	},
	{
		path: "/h5/eventtheme/index",
		name: "H5eventTheme",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/h5/event-theme/index.vue")
	},
	{
		path: "/h5/eventtheme/index2",
		name: "H5eventTheme2",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/h5/event-theme/index2.vue")
	},
	{
		path: "/h5/eventtheme/index3",
		name: "H5eventTheme3",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/h5/event-theme/index3.vue")
	},
	{
		path: "/h5/eventtheme/index4",
		name: "H5eventTheme4",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/h5/event-theme/index4.vue")
	},
	{
		path: "/h5/eventtheme/index5",
		name: "H5eventTheme5",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/h5/event-theme/index5.vue")
	},
	{
		path: "/h5/eventtheme/index6",
		name: "H5eventTheme6",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/h5/event-theme/index6.vue")
	},
	{
		path: "/h5/eventtheme/index7",
		name: "H5eventTheme7",
		meta: {
			title: "专题"
		},
		component: () => import("@/pages/h5/event-theme/index7.vue")
	},
	{
		path: "/h5/success",
		name: "H5success",
		meta: {
			title: "人脸识别核验"
		},
		component: () => import("@/pages/h5/success.vue")
	},



	{
		path: "/iceskating/index",
		name: "iceskating",
		meta: {
			title: "花样滑冰"
		},
		component: () => import("@/pages/ice-skating/index.vue")
	},
	{
		path: "/iceskating/layout",
		name: "iceskatinglayout",
		meta: {
			title: "花样滑冰"
		},
		component: () => import("@/pages/ice-skating/layout.vue")
	},
	{
		path: "/iceskating/details",
		name: "iceskatingdetails",
		meta: {
			title: "详情"
		},
		component: () => import("@/pages/ice-skating/details.vue")
	},
	{
		path: "/iceskating/searchfor",
		name: "iceskatingsearchfor",
		meta: {
			title: "搜索"
		},
		component: () => import("@/pages/ice-skating/searchfor.vue")
	},

	{
		path: "/enversion/index",
		name: "en",
		meta: {
			title: "figure skating"
		},
		component: () => import("@/pages/enversion/index.vue")
	},
	{
		path: "/enversion/layout",
		name: "enlayout",
		meta: {
			title: "figure skating"
		},
		component: () => import("@/pages/enversion/layout.vue")
	},
	{
		path: "/enversion/details",
		name: "endetails",
		meta: {
			title: "details"
		},
		component: () => import("@/pages/enversion/details.vue")
	},
	{
		path: "/enversion/searchfor",
		name: "ensearchfor",
		meta: {
			title: "searchfor"
		},
		component: () => import("@/pages/enversion/searchfor.vue")
	},

	{
		path: "/international/index",
		name: "international",
		meta: {
			title: "国际赛事"
		},
		component: () => import("@/pages/international/index.vue")
	},
	{
		path: "/international/layout",
		name: "internationallayout",
		meta: {
			title: "国际赛事"
		},
		component: () => import("@/pages/international/layout.vue")
	},
	{
		path: "/international/details",
		name: "internationaldetails",
		meta: {
			title: "详情"
		},
		component: () => import("@/pages/international/details.vue")
	},
	{
		path: "/international/searchfor",
		name: "internationalsearchfor",
		meta: {
			title: "搜索"
		},
		component: () => import("@/pages/international/searchfor.vue")
	},

	{
		path: "/internationalEn/index",
		name: "internationalEn",
		meta: {
			title: "international competition"
		},
		component: () => import("@/pages/internationalEn/index.vue")
	},
	{
		path: "/internationalEn/layout",
		name: "internationalEnenlayout",
		meta: {
			title: "international competition"
		},
		component: () => import("@/pages/internationalEn/layout.vue")
	},
	{
		path: "/internationalEn/details",
		name: "internationalEnendetails",
		meta: {
			title: "details"
		},
		component: () => import("@/pages/internationalEn/details.vue")
	},
	{
		path: "/internationalEn/searchfor",
		name: "internationalEnensearchfor",
		meta: {
			title: "searchfor"
		},
		component: () => import("@/pages/internationalEn/searchfor.vue")
	},

	
];

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {
				x: 0,
				y: 0
			};
		}
	}
});


router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title && to.meta.title != '首页') {
		document.title = to.meta.title + '-北京市体育竞赛管理和国际交流中心'
		router.app.$meta().refresh()
	}
	if (to.meta.title && to.meta.title == '首页') {
		document.title = '北京市体育竞赛管理和国际交流中心'
		router.app.$meta().refresh()
	}

	$("#left").hide();
	$("#right").hide();
	next()
})

function isMobileDevice() {
	const sUserAgent = navigator.userAgent.toLowerCase();
	const bIsIpad = sUserAgent.includes('ipad');
	const bIsIphone = sUserAgent.includes('iphone');
	const bIsAndroid = sUserAgent.includes('android');
	const bIsMobile = bIsIpad || bIsIphone || bIsAndroid;

	return bIsMobile;
}

router.beforeEach((to, from, next) => { // 重定向
	if (to.path == '/registerIndex' || to.path == '/h5/registerIndex') {
		if (store.state.token == '') {
			MessageBox.alert('当前暂未登录，请先登录', '系统提示', {
			  confirmButtonText: '重新登录',
			  type: 'warning'
			}).then(async () => {
			  //注销去登录页
			  store.commit("EXIT_TOKEN")
			  if(window.location.href.includes('/h5')){
				location.href = '/h5/login'  
			  }else{
				location.href = '/login'  
			  }
			})
		} else {
			if (to.matched.length === 0) {
				if (isMobileDevice()) {
					next('/h5')
				} else {
					next('/')
				}
			} else {
				next()
			}
		}
	} else {
		if (to.matched.length === 0) {
			if (isMobileDevice()) {
				next('/h5')
			} else {
				next('/')
			}
		} else {
			next()
		}
	}

})

export default router;